import React from "react";
import { useTranslation } from "react-i18next";
import { TFunction } from "i18next";
import { IconButton, Stack, Typography } from "@mui/material";

import {
  MdOutlineTextFields,
  MdOutlineNumbers,
  MdOutlineDateRange,
  MdOutlineTimer,
  MdOutlineDataArray,
  MdOutlineFilePresent,
} from "react-icons/md";
import { RxComponentBoolean } from "react-icons/rx";
import { TbNumber123, TbSelect } from "react-icons/tb";
import { CopyIcon } from "components/styled-icons";
import { useNotification } from "providers/notification";
import type {
  ApplicationFormSchema,
  ApplicationItemSchemaType,
} from "services/application-types";
import { StandardObject } from "utils";

export const getIconFromItemSchemaType = (
  type: ApplicationItemSchemaType,
  size: number = 20
) => {
  switch (type) {
    case "boolean":
      return <RxComponentBoolean size={size} />;
    case "date":
      return <MdOutlineDateRange size={size} />;
    case "datetime":
      return <MdOutlineTimer size={size} />;
    case "enum":
      return <TbSelect size={size} />;
    case "integer":
      return <TbNumber123 size={size} />;
    case "text":
      return <MdOutlineTextFields size={size} />;
    case "array":
      return <MdOutlineDataArray size={size} />;
    case "file":
      return <MdOutlineFilePresent size={size} />;
    case "number":
      return <MdOutlineNumbers size={size} />;
    default:
      return <MdOutlineTextFields size={size} />;
  }
};

export const getLabelFromItemSchemaType = (
  type: ApplicationItemSchemaType,
  t: TFunction<"application">
) => {
  switch (type) {
    case "boolean":
      return t("itemSchemas.schemaType.boolean");
    case "date":
      return t("itemSchemas.schemaType.date");
    case "datetime":
      return t("itemSchemas.schemaType.datetime");
    case "enum":
      return t("itemSchemas.schemaType.enum");
    case "integer":
      return t("itemSchemas.schemaType.integer");
    case "text":
      return t("itemSchemas.schemaType.text");
    case "array":
      return t("itemSchemas.schemaType.array");
    case "file":
      return t("itemSchemas.schemaType.file");
    case "number":
      return t("itemSchemas.schemaType.number");
    default:
      return t("itemSchemas.schemaType.text");
  }
};

export const getItemSchemaTypes: () => ApplicationItemSchemaType[] = () => [
  "text",
  "boolean",
  "integer",
  "number",
  "date",
  "datetime",
  "enum",
  "file",
];

export const getItemSchemaDefaultFields = (
  type: ApplicationItemSchemaType,
  isArray?: boolean
) => {
  const commonFields = {
    name: "",
    type,
    localizedLabels: {},
    mandatory: false,
  };
  if (!isArray) {
    return {
      ...commonFields,
      format: {},
    };
  } else {
    return {
      ...commonFields,
      elementSchema: {
        ...commonFields,
        format: {},
      },
      appendable: false,
    };
  }
};

type ItemSchemaError = "NAME_REQUIRED" | "NAME_DUPLICATED";

export interface ItemSchemaErrorMap {
  [key: string]: ItemSchemaError[];
}

export const checkItemSchemaMapForError = (
  itemSchemaMap: StandardObject<ApplicationFormSchema>
) => {
  const errorMap: ItemSchemaErrorMap = {};
  const checkedNames: StandardObject<string> = {};
  Object.keys(itemSchemaMap).forEach((key) => {
    const item = itemSchemaMap[key];
    // Check name
    if (!item.name) {
      if (!errorMap[key]) {
        errorMap[key] = [];
      }
      errorMap[key].push("NAME_REQUIRED");
    } else if (!!checkedNames[item.name]) {
      if (!errorMap[key]) {
        errorMap[key] = [];
      }
      errorMap[key].push("NAME_DUPLICATED");
      const checkedKey = checkedNames[item.name];
      if (!errorMap[checkedKey]) {
        errorMap[checkedKey] = [];
      }
      errorMap[checkedNames[item.name]].push("NAME_DUPLICATED");
    }
    checkedNames[item.name] = key;
  });
  return errorMap;
};

interface SubtextWithCopyProps {
  displayText: string;
  copyText: string;
  bgcolor?: string;
}

export const getItemSchemaFromMap = (
  schemaMap: StandardObject<ApplicationFormSchema>
) => {
  return Object.entries(schemaMap)
    .filter(([k, _itm]) => !!k)
    .map(([_k, itm]) => {
      const { key, ...ret } = itm;
      return ret;
    });
};

export const SubtextWithCopy: React.FC<SubtextWithCopyProps> = ({
  displayText,
  copyText,
  bgcolor = "grey.200",
}) => {
  const { t } = useTranslation();
  const [nameHovered, setNameHovered] = React.useState<boolean>(false);
  const { showNotification } = useNotification();
  return (
    <Stack
      direction="row"
      alignItems="center"
      spacing={1}
      onMouseEnter={() => setNameHovered(true)}
      onMouseLeave={() => setNameHovered(false)}
      sx={{
        border: 0,
        bgcolor: nameHovered ? bgcolor : "transparent",
        borderRadius: "4px",
        width: "fit-content",
      }}
    >
      <Typography
        variant="body2"
        color={nameHovered ? "disabled.darker" : "disabled.dark"}
        sx={{
          opacity: 0.8,
          wordBreak: "break-all",
        }}
      >
        {displayText}
      </Typography>
      <IconButton
        sx={{
          visibility: nameHovered ? "visible" : "hidden",
        }}
        size="small"
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          navigator.clipboard.writeText(copyText);
          showNotification(t("actions.copied"), "info");
        }}
      >
        <CopyIcon />
      </IconButton>
    </Stack>
  );
};

export const checkItemSchemaMatchSearch = (
  itm: ApplicationFormSchema,
  search: string
) => {
  let isHidden = false;
  const searchLowercase = search.toLowerCase();
  if (Object.keys(itm?.localizedLabels ?? {}).length === 0) {
    isHidden = !itm.name.toLowerCase().includes(searchLowercase);
  } else {
    isHidden =
      Object.values(itm?.localizedLabels ?? {}).reduce((acc, cur) => {
        acc = acc && !cur.toLowerCase().includes(searchLowercase);
        return acc;
      }, true) && !itm.name.toLowerCase().includes(searchLowercase);
  }
  return !isHidden;
};
