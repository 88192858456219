import React from "react";
import {
  DashboardIcon,
  ApplicationIcon,
  ProductIcon,
  ClientIcon,
  BindingIcon,
  DeviceIcon,
  AuthHistoryIcon,
  EKYCIcon,
  MessagingIcon,
  NewMessageIcon,
  MessageHistoryIcon,
  TemplateIcon,
  ResourceIcon,
  ScheduleTaskIcon,
  TaskIcon,
  ExecutionHistoryIcon,
  AdminGearIcon,
  AdminIcon,
  AdminGroupIcon,
  SystemIcon,
  ConfigIcon,
  InfoIcon,
  HeartBeatIcon,
  PendingReviewIcon,
  TransactionHistoryIcon,
  EkycFaceCompareIcon,
  FaceCaptureIcon,
  ApplicationFormIcon,
  UserApplicationIcon,
  ApplicationPendingProcessIcon,
  ApplicationOngoingIcon,
} from "components/styled-icons";
import type { StandardObject } from "utils";
import type { TFuncKey } from "i18next";

export interface NavConfigItem {
  key: TFuncKey<"nav">;
  path?: string;
  badgeContent?: React.ReactNode;
  badgeKey?: string;
  icon: React.ReactNode;
  requiredScopes?: string[];
  children?: NavConfigItem[];
}

export interface NavConfigGroup {
  key: TFuncKey<"nav">;
  children: NavConfigItem[];
  disableDivider?: boolean;
  badgeContent?: React.ReactNode;
  requiredScopes?: string[];
  flags?: StandardObject<boolean>;
}

const pathRoot = "/t";

export const traditionalNavConfigNested: NavConfigGroup[] = [
  {
    key: "technical-portal.application.title",
    children: [
      {
        key: "technical-portal.application.application",
        path: `${pathRoot}/application`,
        icon: <ApplicationIcon />,
        requiredScopes: ["APPLICATION_VIEW"],
      },
      {
        key: "technical-portal.application.product",
        path: `${pathRoot}/product`,
        icon: <ProductIcon />,
        requiredScopes: ["PRODUCT_VIEW"],
      },
    ],
  },
  {
    key: "technical-portal.client.title",
    children: [
      {
        key: "technical-portal.client.client",
        path: `${pathRoot}/client`,
        icon: <ClientIcon />,
        requiredScopes: ["CLIENT_VIEW"],
      },
      {
        key: "technical-portal.client.device-binding.parent",
        icon: <BindingIcon />,
        children: [
          {
            key: "technical-portal.client.device-binding.registered-device",
            path: `${pathRoot}/binding/registered-device`,
            requiredScopes: ["DEVICE_BINDING_REG_VIEW"],
            icon: <DeviceIcon />,
          },
          {
            key: "technical-portal.client.device-binding.authentication-history",
            path: `${pathRoot}/binding/authentication-history`,
            requiredScopes: ["DEVICE_BINDING_AUTH_VIEW"],
            icon: <AuthHistoryIcon />,
          },
        ],
      },
      {
        key: "technical-portal.client.ekyc",
        path: `${pathRoot}/ekyc`,
        requiredScopes: ["EKYC_VIEW"],
        icon: <EKYCIcon />,
      },
      {
        key: "technical-portal.client.messaging.parent",
        icon: <MessagingIcon />,
        children: [
          {
            key: "technical-portal.client.messaging.new-message",
            path: `${pathRoot}/messaging/new-message`,
            requiredScopes: [
              "MESSAGING_SMS_NEW",
              "MESSAGING_EMAIL_NEW",
              "MESSAGING_PUSH_NEW",
            ],
            icon: <NewMessageIcon />,
          },
          {
            key: "technical-portal.client.messaging.template",
            path: `${pathRoot}/messaging/messaging-template`,
            requiredScopes: ["MESSAGING_TEMPLATE_VIEW"],
            icon: <TemplateIcon />,
          },
          {
            key: "technical-portal.client.messaging.history",
            path: `${pathRoot}/messaging/messaging-history`,
            requiredScopes: ["MESSAGE_DELIVERY_HISTORY"],
            icon: <MessageHistoryIcon />,
          },
        ],
      },
    ],
  },
  {
    key: "technical-portal.management.title",
    children: [
      {
        key: "technical-portal.management.system-resource",
        path: `${pathRoot}/resources/system-resource`,
        requiredScopes: ["RESOURCES_SYSTEM_VIEW"],
        icon: <ResourceIcon />,
      },
      {
        key: "technical-portal.management.task.parent",
        icon: <ScheduleTaskIcon />,
        children: [
          {
            key: "technical-portal.management.task.task",
            path: `${pathRoot}/task/task-job`,
            icon: <TaskIcon />,
            requiredScopes: ["TASK_VIEW", "TASK_JOB_VIEW"],
          },
          {
            key: "technical-portal.management.task.execution-history",
            path: `${pathRoot}/task/execution-history`,
            icon: <ExecutionHistoryIcon />,
            requiredScopes: ["TASK_JOB_VIEW", "TASK_JOB_EXECUTION_HISTORY"],
          },
        ],
      },
      {
        key: "technical-portal.management.administration.parent",
        icon: <AdminIcon />,
        children: [
          {
            key: "technical-portal.management.administration.admin",
            path: `${pathRoot}/management/admin`,
            icon: <AdminGearIcon />,
            requiredScopes: ["ADMIN_USER_VIEW"],
          },
          {
            key: "technical-portal.management.administration.admin-group",
            path: `${pathRoot}/management/admin-group`,
            icon: <AdminGroupIcon />,
            requiredScopes: ["ADMIN_GROUP_VIEW"],
          },
        ],
      },
      {
        key: "technical-portal.management.system.parent",
        icon: <SystemIcon />,
        children: [
          // {
          //   name: "Encryption Key",
          //   path: "",
          //   icon: <KeyIcon />,
          // },
          {
            key: "technical-portal.management.system.tenant-info",
            path: `${pathRoot}/system/tenant-information`,
            icon: <InfoIcon />,
            requiredScopes: ["TENANT_INFORMATION_VIEW"],
          },
          {
            key: "technical-portal.management.system.service-configuration",
            path: `${pathRoot}/system/service-configuration`,
            icon: <ConfigIcon />,
            requiredScopes: ["TENANT_CONFIGURATION_VIEW"],
          },
          {
            key: "technical-portal.management.system.service-health",
            path: `${pathRoot}/system/tenanted-service-health`,
            icon: <HeartBeatIcon />,
            requiredScopes: ["TENANT_SERVICE_HEALTH_VIEW"],
          },
        ],
      },
    ],
  },
];

const ekycPathRoot = "/ekyc-service";
export const ekycServiceNavConfigNested: NavConfigGroup[] = [
  {
    key: "service-menu.ekyc-service",
    flags: {
      isRoot: true,
    },
    disableDivider: true,
    children: [
      {
        key: "ekyc-service.dashboard",
        path: `${ekycPathRoot}/dashboard`,
        icon: <DashboardIcon />,
        requiredScopes: ["EKYC_VIEW"],
      },
      {
        key: "ekyc-service.ekyc",
        badgeKey: "EKYC",
        icon: <EKYCIcon />,
        requiredScopes: ["EKYC_VIEW"],
        children: [
          {
            key: "ekyc-service.pending-review",
            badgeKey: "EKYC",
            path: `${ekycPathRoot}/ekyc/pending-review`,
            icon: <PendingReviewIcon />,
            requiredScopes: ["EKYC_VIEW"],
          },
          {
            key: "ekyc-service.transaction-history",
            path: `${ekycPathRoot}/ekyc/transaction-history`,
            icon: <TransactionHistoryIcon />,
            requiredScopes: ["EKYC_VIEW"],
          },
        ],
      },
      {
        key: "ekyc-service.face-capture",
        badgeKey: "FACE_CAPTURE",
        icon: <FaceCaptureIcon />,
        requiredScopes: ["EKYC_VIEW"],
        children: [
          {
            key: "ekyc-service.pending-review",
            badgeKey: "FACE_CAPTURE",
            path: `${ekycPathRoot}/face-capture/pending-review`,
            icon: <PendingReviewIcon />,
            requiredScopes: ["EKYC_VIEW"],
          },
          {
            key: "ekyc-service.transaction-history",
            path: `${ekycPathRoot}/face-capture/transaction-history`,
            icon: <TransactionHistoryIcon />,
            requiredScopes: ["EKYC_VIEW"],
          },
        ],
      },
      {
        key: "ekyc-service.face-compare",
        badgeKey: "FACE_COMPARE",
        icon: <EkycFaceCompareIcon />,
        requiredScopes: ["EKYC_VIEW"],
        children: [
          {
            key: "ekyc-service.pending-review",
            badgeKey: "FACE_COMPARE",
            path: `${ekycPathRoot}/face-compare/pending-review`,
            icon: <PendingReviewIcon />,
            requiredScopes: ["EKYC_VIEW"],
          },
          {
            key: "ekyc-service.transaction-history",
            path: `${ekycPathRoot}/face-compare/transaction-history`,
            icon: <TransactionHistoryIcon />,
            requiredScopes: ["EKYC_VIEW"],
          },
        ],
      },
      {
        key: "ekyc-service.provider-configuration",
        path: `${ekycPathRoot}/provider-configuration`,
        icon: <ConfigIcon />,
        requiredScopes: ["TENANT_CONFIGURATION_VIEW"],
      },
    ],
  },
];

const applicationPathRoot = "/application-service";
export const applicationServiceNavConfigNested: NavConfigGroup[] = [
  {
    key: "application-service.application",
    flags: {
      isRoot: true,
    },
    disableDivider: true,
    children: [
      {
        key: "application-service.application",
        path: `${applicationPathRoot}/application`,
        icon: <UserApplicationIcon />,
        requiredScopes: ["APPLICATION_VIEW"],
        children: [
          {
            key: "application-service.pending-process",
            path: `${applicationPathRoot}/application/pending`,
            icon: <ApplicationPendingProcessIcon />,
            requiredScopes: ["APPLICATION_VIEW"],
          },
          {
            key: "application-service.ongoing",
            path: `${applicationPathRoot}/application/ongoing`,
            icon: <ApplicationOngoingIcon />,
            requiredScopes: ["APPLICATION_VIEW"],
          },
          {
            key: "application-service.application-history",
            path: `${applicationPathRoot}/application/history`,
            icon: <TransactionHistoryIcon />,
            requiredScopes: ["APPLICATION_VIEW"],
          },
        ],
      },
      {
        key: "application-service.application-form",
        path: `${applicationPathRoot}/form`,
        icon: <ApplicationFormIcon />,
        requiredScopes: ["APPLICATION_FORM_VIEW"],
      },
    ],
  },
];
