import { EnumValueLabel } from "components/application/edit-form/enum-label-map-input";
import {
  defaultLanguage,
  SupportedLocale,
} from "components/supported-language";
import type { StandardObject } from "utils";

export type SchemaComponentType =
  | "emptybox"
  | "dummy"
  | "card"
  | "textfield"
  | "boolean"
  | "datetime"
  | "enum"
  | "imgleftcard"
  | "imgrightcard"
  | "imgtopcard"
  | "tabimgcard"
  | "imgimgcard"
  | "carousel";

export type SchemaComponentCategory = "CONTAINER" | "ITEM" | "DUMMY";
export type DroppableGridAccept =
  | "ITEM"
  | "CONTAINER"
  | "TEMPLATE_ITEM"
  | "TEMPLATE_CONTAINER"
  | "DUMMY";

export type DataSchemaType =
  | "string"
  | "number"
  | "integer"
  | "boolean"
  | "datetime"
  | "array"
  | "file";

export interface DataSchema {
  name: string;
  type: DataSchemaType;
  options?: string[];
  example?: string;
  mandatory?: boolean; // Default false
  description?: string;
}

export interface DisplaySchemaProp {
  [propName: string]: {
    name: string | string[] | null;
    format?: string;
    valueLabel?: StandardObject;
    valueColor?: StandardObject;
    defaultColor?: string;
  };
}

export interface SchemaItem {
  componentType: SchemaComponentType;
  componentProps: StandardObject;
  schemaProps?: DisplaySchemaProp;
  locales?: Record<SupportedLocale, StandardObject>;
}

export interface GridItem extends SchemaItem {
  // order: number;
  gridSpan: number;
  maxCol?: number;
  parentKey?: string;
}

export interface GridItemMap {
  [key: string]: GridItem;
}

export interface GridArrItem {
  key: string;
  items?: GridArrItem[];
}
export interface DragItem extends SchemaItem {
  schemaKey?: string;
  draggable: boolean;
}

export interface PortalDisplaySchema {
  lastSavedAt?: number;
  maxCol: number;
  gridItems: GridItemMap;
  gridArr: GridArrItem[];
}

export const getCategoryFromType = (type: SchemaComponentType) => {
  switch (type) {
    case "card":
    case "imgleftcard":
    case "imgrightcard":
    case "imgtopcard":
    case "tabimgcard":
    case "imgimgcard":
    case "carousel":
      return "CONTAINER";
    case "textfield":
    case "boolean":
    case "datetime":
    case "enum":
      return "ITEM";
    case "dummy":
      return "DUMMY";
    default:
      return "DUMMY";
  }
};

export const checkHasItem = (type: SchemaComponentType) => {
  switch (type) {
    case "imgimgcard":
      return false;
    default:
      return getCategoryFromType(type) === "CONTAINER";
  }
};

export const calculateNewComponentGridCount = (
  gridArr: GridArrItem[],
  gridItems: GridItemMap,
  pMaxCol: number
) => {
  const maxCol = Number(pMaxCol);
  const reminder = gridArr.reduce((acc, itm) => {
    if (!gridItems[itm.key]) {
      return acc;
    }
    let newAcc = acc + gridItems[itm.key].gridSpan;
    if (newAcc > maxCol) {
      newAcc = gridItems[itm.key].gridSpan;
    } else if (newAcc === maxCol) {
      newAcc = 0;
    }
    return newAcc;
  }, 0);
  // When reminder = 0, or in case last grid item has grid span >= maxCol
  const ret = reminder === 0 || reminder >= maxCol ? maxCol : maxCol - reminder;
  return ret;
};

export const getDefaultComponentPropsFromType = (
  type: SchemaComponentType
): StandardObject => {
  switch (type) {
    case "card":
    case "imgleftcard":
    case "imgrightcard":
    case "imgtopcard":
    case "imgimgcard":
      return {
        cardProps: {},
        cardHeaderProps: {},
      };
    case "carousel":
    case "tabimgcard":
      return {
        cardProps: {},
        cardHeaderProps: {},
        tabProps: {},
        tabImgProps: {},
      };
    case "enum":
      return {
        textfieldProps: {},
        chipProps: {},
      };
    default:
      return {};
  }
};

export const getDefaultSchemaFromType = (
  type: SchemaComponentType
): DisplaySchemaProp => {
  switch (type) {
    case "textfield":
    case "boolean":
    case "imgleftcard":
    case "imgrightcard":
    case "imgtopcard":
      return {
        value: { name: null },
      };
    case "tabimgcard":
    case "carousel":
    case "imgimgcard":
      return {
        value: {
          name: [],
          valueLabel: {},
        },
      };
    case "datetime":
      return {
        value: {
          name: null,
          format: "YYYY-MM-DD HH:mm:ss ZZ",
        },
      };
    case "enum":
      return {
        value: {
          name: null,
          valueLabel: {},
          valueColor: {},
          defaultColor: "default",
        },
      };
    default:
      return {};
  }
};

export const getRequiredFieldsFromType = (
  type: SchemaComponentType,
  locales?: SchemaItem["locales"]
): string[] => {
  const ret: string[] = [];
  switch (type) {
    case "card":
      ret.push("componentProps.cardHeaderProps.title");
      if (!!locales) {
        Object.keys(locales).forEach((locale) => {
          ret.push(`locales.${locale}.componentProps.cardHeaderProps.title`);
        });
      }
      break;
    case "imgleftcard":
    case "imgrightcard":
    case "imgtopcard":
      ret.push(
        "componentProps.cardHeaderProps.title",
        "schemaProps.value.name",
      );
      if (!!locales) {
        Object.keys(locales).forEach((locale) => {
          ret.push(`locales.${locale}.componentProps.cardHeaderProps.title`);
        });
      }
      break;
    case "tabimgcard":
    case "carousel":
    case "imgimgcard":
      ret.push(
        "componentProps.cardHeaderProps.title",
        "schemaProps.value.name",
        "schemaProps.value.valueLabel"
      );
      if (!!locales) {
        Object.keys(locales).forEach((locale) => {
          ret.push(`locales.${locale}.componentProps.cardHeaderProps.title`);
          ret.push(`locales.${locale}.schemaProps.value.valueLabel`);
        });
      }
      break;

    case "textfield":
    case "datetime":
      ret.push("componentProps.label", "schemaProps.value.name");
      if (!!locales) {
        Object.keys(locales).forEach((locale) => {
          ret.push(`locales.${locale}.componentProps.label`);
        });
      }
      break;
    case "boolean":
      ret.push("componentProps.title", "schemaProps.value.name");
      if (!!locales) {
        Object.keys(locales).forEach((locale) => {
          ret.push(`locales.${locale}.componentProps.title`);
        });
      }
      break;
    case "enum":
      ret.push(
        "componentProps.textfieldProps.label",
        "schemaProps.value.name",
        "schemaProps.value.valueLabel"
      );
      if (!!locales) {
        Object.keys(locales).forEach((locale) => {
          ret.push(`locales.${locale}.componentProps.textfieldProps.label`);
          ret.push(`locales.${locale}.schemaProps.value.valueLabel`);
        });
      }
      break;
    default:
      break;
  }
  return ret;
};

export const checkNestedObjHasValue = (
  key: string,
  obj: StandardObject | undefined
) => {
  const keys = key.split(".");
  if (obj === undefined) {
    return false;
  }
  let ret = true;
  let j = obj;
  keys.forEach((k) => {
    if (!j[k]) {
      ret = false;
    }
    if (ret) {
      j = j[k];
    }
  });
  return ret;
};

export const getValueFromNestedObj = (
  obj: StandardObject | undefined,
  key: string
) => {
  const keys = key.split(".");
  const ret = keys.reduce((acc, k) => {
    if (acc === undefined) {
      return undefined;
    }
    return acc[k];
  }, obj);
  return ret;
};

export const getValueAndOnChangeKey = (
  obj: StandardObject | undefined,
  curLocale: SupportedLocale,
  key: string
) => {
  if (curLocale !== defaultLanguage) {
    return {
      value: getValueFromNestedObj(obj, `locales.${curLocale}.${key}`),
      onChangeKey: `locales.${curLocale}.${key}`,
    };
  } else {
    return {
      value: getValueFromNestedObj(obj, key),
      onChangeKey: key,
    };
  }
};

export const getHasMissingProps = (
  componentType: SchemaComponentType,
  componentProps: StandardObject,
  schemaProps?: DisplaySchemaProp,
  locales?: SchemaItem["locales"]
) => {
  const requiredProps = getRequiredFieldsFromType(componentType, locales);
  const hasMissingProps = requiredProps.some((prop) => {
    const ret =
      (prop.startsWith("componentProps") &&
        !checkNestedObjHasValue(
          prop.replace("componentProps.", ""),
          componentProps
        )) ||
      (prop.startsWith("locales") &&
        (!locales ||
          !checkNestedObjHasValue(prop.replace("locales.", ""), locales))) ||
      (prop.startsWith("schemaProps") &&
        (!schemaProps ||
          !checkNestedObjHasValue(
            prop.replace("schemaProps.", ""),
            schemaProps
          )));
    return ret;
  });
  return hasMissingProps;
};
