import {
  configureStore,
  combineReducers,
  createAction,
} from "@reduxjs/toolkit";
import type { Action } from "@reduxjs/toolkit";
import authReducer, { defaultState } from "./authSlice";
import adminManagementReducer from "./adminManagementSlice";
import adminGroupManagementReducer from "./adminGroupManagementSlice";
import fubonCreditApplicationReducer from "./fubonCreditApplicationSlice";
import applicationReducer from "./applicationSlice";
import applicationSchemaReducer from "./applicationSchemaSlice";
import applicationItemSchemaReducer from "./applicationItemSchemaSlice";  
import productReducer from "./productSlice";
import clientReducer from "./clientSlice";
import deviceRegistrationReducer from "./deviceRegistrationSlice";
import bindingAuthHistoryReducer from "./bindingAuthHistorySlice";
import messagingHistoryReducer from "./messagingHistorySlice";
import messagingTemplateReducer from "./messagingTemplateSlice";
import newMessageReducer from "./newMessageSlice";
import ekycReducer from "./ekycSlice";
import executionHistoryReducer from "./executionHistorySlice";
import taskJobReducer from "./taskJobSlice";
import tenantedServiceHealthReducer from "./tenantedServiceHealthSlice";
import historyReducer from "./histSlice";
import systemResourceReducer from "./systemResourceSlice";
import navReducer from "./navSlice";
import { getObjectUpdates, removeState } from "utils";

export const resetStore = createAction<number>("resetStore");
const appReducer = combineReducers({
  history: historyReducer,
  auth: authReducer,
  adminManagement: adminManagementReducer,
  adminGroupManagement: adminGroupManagementReducer,
  application: applicationReducer,
  schema: applicationSchemaReducer,
  itemSchema: applicationItemSchemaReducer,
  fubonCreditApplication: fubonCreditApplicationReducer,
  product: productReducer,
  client: clientReducer,
  deviceRegistration: deviceRegistrationReducer,
  bindingAuthHistory: bindingAuthHistoryReducer,
  messagingHistory: messagingHistoryReducer,
  messagingTemplate: messagingTemplateReducer,
  newMessage: newMessageReducer,
  executionHistory: executionHistoryReducer,
  tenantedServiceHealth: tenantedServiceHealthReducer,
  taskJob: taskJobReducer,
  ekyc: ekycReducer,
  systemResource: systemResourceReducer,
  nav: navReducer,
});

export type RootState = ReturnType<typeof appReducer> | undefined;

const rootReducer = (state: RootState, action: Action) => {
  // Before handled by reducer slices
  if (action.type === "resetStore" || action.type === "auth/clearAuth") {
    // Clear sessionStorage
    removeState("auth", Object.keys(defaultState));
  }
  if (action.type === "resetStore") {
    if (process.env.NODE_ENV === "development") {
      console.log("Reset store.");
    }
    return appReducer(undefined, action);
  }
  return appReducer(state, action);
};

// const recordActionMiddleware: Middleware<{}, RootState> =
//   (storeAPI) => (next) => (action) => {
//     const newAction = action as UnknownAction;
//     if (
//       newAction &&
//       newAction.type &&
//       newAction.type !== "history/recordAction"
//     ) {
//       // Exclude createAsyncThunk pending actions
//       const pendingPattern = /.*\/.*\/pending/;
//       if (!pendingPattern.test(newAction.type)) {
//         storeAPI.dispatch(recordAction(newAction.type));
//       }
//     }
//     return next(action);
//   };

const Store = configureStore({
  reducer: rootReducer,
  // middleware: (getDefaultMiddleware) =>
  //   getDefaultMiddleware().prepend(recordActionMiddleware),
});

let currentState: RootState;
if (process.env.NODE_ENV === "development") {
  Store.subscribe(() => {
    const prevState = currentState ?? {};
    const newState = Store.getState();
    const update = getObjectUpdates(prevState, newState);
    console.log("State change after dispatch: ", update);
    currentState = newState;
  });
}

export default Store;
