import { createSlice, createSelector } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import type {
  ApplicationFormSchema,
  ApplicationItemSchemaType,
  ArrayItemSchema,
  IterableItemSchema,
} from "services/application-types";
import { hashCode, type StandardObject } from "utils";
import type {
  InsertDraggingAsDummyPayload,
  FinalizeGridArrPayload,
} from "./applicationSchemaSlice";
import { getItemSchemaDefaultFields } from "components/application/item-schema/utils";

interface ApplicationItemSchemaState {
  itemSchemaMap: StandardObject<ApplicationFormSchema>;
  initialItemSchemaMap: StandardObject<ApplicationFormSchema>;
  itemSchemaArr: {
    key: string;
    type?: ApplicationItemSchemaType; // This type exclude array
  }[];
  editingItem: string | null;
  search: string;
  filterType: ApplicationItemSchemaType | "";
}

const initialState: ApplicationItemSchemaState = {
  itemSchemaMap: {},
  initialItemSchemaMap: {},
  itemSchemaArr: [],
  editingItem: null,
  search: "",
  filterType: "",
};

export const itemSchemaSlice = createSlice({
  name: "itemSchema",
  initialState,
  reducers: {
    setEditingItem: (state, action: PayloadAction<string | null>) => {
      state.editingItem = action.payload;
    },
    setEditingItemByName: (state, action: PayloadAction<string>) => {
      const name = action.payload;
      // Search name in item schema map
      const key = Object.values(state.itemSchemaMap).find(
        (itm) => itm.name === name
      )?.key;
      if (!!key) {
        state.editingItem = key;
      }
    },
    setSearch: (state, action: PayloadAction<string>) => {
      state.search = action.payload;
    },
    setFilterType: (
      state,
      action: PayloadAction<ApplicationItemSchemaType>
    ) => {
      state.filterType = action.payload;
    },
    updateItemSchemaMapItem: (
      state,
      action: PayloadAction<ApplicationFormSchema>
    ) => {
      const key = action.payload.key as string;
      state.itemSchemaMap[key] = action.payload;
    },
    alterItemSchemaItemType: (
      state,
      action: PayloadAction<{ type: ApplicationItemSchemaType }>
    ) => {
      const key = state.editingItem;
      if (key === null) {
        return;
      }
      const newType = action.payload.type;
      const oldFields = { ...state.itemSchemaMap[key] };
      // Keep basic field only
      const isArray = oldFields.type === "array";
      const newFields = getItemSchemaDefaultFields(
        newType,
        isArray
      ) as ApplicationFormSchema;
      state.itemSchemaMap[key] = {
        ...newFields,
        name: oldFields.name,
        localizedLabels: oldFields.localizedLabels,
        mandatory: oldFields.mandatory,
        key: key,
      };
      const idx = state.itemSchemaArr.findIndex((itm) => itm.key === key);
      state.itemSchemaArr[idx].type = newType;
    },
    arrayConvertItem: (state) => {
      const key = state.editingItem;
      if (!key) {
        return;
      }
      const currentItem = state.itemSchemaMap[key];
      const isArray = currentItem.type === "array";
      if (!isArray) {
        // Convert to array data structure
        const { format, name, type, ...rest } = currentItem;
        const newItem: ArrayItemSchema = {
          type: "array",
          name: name,
          ...rest,
          elementSchema: {
            type: type,
            ...(!!format && {
              format,
            }),
          } as IterableItemSchema,
          appendable: type === "file",
        };
        state.itemSchemaMap[key] = newItem;
      } else {
        const {
          elementSchema,
          type: _type,
          name,
          appendable,
          ...rest
        } = currentItem;
        const { type, format } = elementSchema;
        const newItem = {
          ...rest,
          type: type,
          name: name,
          ...(!!format && {
            format,
          }),
        } as ApplicationFormSchema;
        state.itemSchemaMap[key] = newItem;
      }
    },
    addItemSchemaItem: (
      state,
      action: PayloadAction<ApplicationFormSchema>
    ) => {
      const key = action.payload.key as string;
      state.itemSchemaMap[key] = action.payload;
      state.itemSchemaArr.push({ key, type: action.payload.type });
      state.editingItem = key;
    },
    deleteItemSchemaItem: (state) => {
      const key = state.editingItem;
      if (!!key) {
        if (!!state.itemSchemaMap[key]) {
          delete state.itemSchemaMap[key];
        }
        state.itemSchemaArr = state.itemSchemaArr.filter(
          (itm) => itm.key !== key
        );
        state.editingItem = null;
      }
    },
    setItemSchema: (
      state,
      action: PayloadAction<{
        itemSchemas: ApplicationFormSchema[];
        orders: string[];
      }>
    ) => {
      // Find editing item name
      const editingItemName = state.editingItem
        ? (state.itemSchemaMap[state.editingItem]?.name ?? null)
        : null;

      const newMap = action.payload.itemSchemas.reduce((acc, schema) => {
        const newKey = hashCode(`${schema.name}-${Date.now()}`).toString();
        let newSchema: ApplicationFormSchema;
        if (schema.type === "array") {
          const { elementSchema, ...rest } = schema;
          const { type, format } = elementSchema;
          newSchema = {
            key: newKey,
            ...rest,
            ...(!!elementSchema && {
              elementSchema: {
                type,
                format,
              } as IterableItemSchema,
            }),
          };
        } else {
          const { format, ...rest } = schema;
          newSchema = {
            key: newKey,
            ...rest,
            ...(!!format && {
              format,
            }),
          } as ApplicationFormSchema;
        }
        acc[newKey] = newSchema;
        return acc;
      }, {} as StandardObject<ApplicationFormSchema>);
      state.itemSchemaMap = newMap;
      state.initialItemSchemaMap = newMap;
      const orders = action.payload.orders;
      const keys = Object.values(state.itemSchemaMap)
        .sort((a, b) => {
          return orders.indexOf(a.name) - orders.indexOf(b.name);
        })
        .map((itm) => itm.key as string);
      state.itemSchemaArr = keys.map((k) => {
        const type =
          newMap[k].type === "array"
            ? newMap[k].elementSchema.type
            : newMap[k].type;
        return {
          key: k,
          type: type,
        };
      });
      // Update editing item
      let key: string | null;
      if (editingItemName === null) {
        key = null;
      } else {
        key =
          Object.values(newMap).find((itm) => itm.name === editingItemName)
            ?.key ?? null;
      }
      state.editingItem = key;
    },
    insertDraggingAsDummy: (
      state,
      action: PayloadAction<InsertDraggingAsDummyPayload>
    ) => {
      const { dragKey, gridKey } = action.payload;
      // create new gridArr without dummy
      let newArr: { key: string; type?: ApplicationItemSchemaType }[];
      newArr = [...state.itemSchemaArr].filter((itm) => itm.key !== "dummy");

      const dragIdx = newArr.findIndex((itm) => itm.key === dragKey);
      const gridIdx = newArr.findIndex((itm) => itm.key === gridKey);
      if (dragIdx < 0 || gridIdx < 0) {
        return;
      }
      // Update dummy item
      // const dragItm = state.itemSchemaMap[dragKey];
      if (dragIdx < gridIdx) {
        // drag forward, insert dummy after gridIdx
        newArr.splice(gridIdx + 1, 0, { key: "dummy" });
        // newGridArr.splice(gridIdx + 1, 1);
      } else {
        // drag backward, insert dummy before gridIdx
        newArr.splice(gridIdx, 0, { key: "dummy" });
      }
      state.itemSchemaArr = newArr;
    },
    removeDummy: (state) => {
      state.itemSchemaArr = state.itemSchemaArr.filter(
        (itm) => itm.key !== "dummy"
      );
    },
    finalizeGridArr: (state, action: PayloadAction<FinalizeGridArrPayload>) => {
      // set dummy to dragKey and remove dragKey
      const { dragKey } = action.payload;
      // Check parentKey
      let newArr: { key: string; type?: ApplicationItemSchemaType }[];
      newArr = [...state.itemSchemaArr];

      const dragIdx = newArr.findIndex((itm) => itm.key === dragKey);
      const dummyIdx = newArr.findIndex((itm) => itm.key === "dummy");
      if (dragIdx < 0 || dummyIdx < 0) {
        return;
      }
      newArr[dummyIdx].key = dragKey;
      newArr[dummyIdx].type = newArr[dragIdx].type;
      newArr[dragIdx].key = "dummy";
      newArr[dragIdx].type = undefined;
      newArr = newArr.filter((itm) => itm.key !== "dummy");
      state.itemSchemaArr = newArr;
    },
  },
});

const selectItemSchema = (state: { itemSchema: ApplicationItemSchemaState }) =>
  state.itemSchema;

export const selectItemSchemaState = createSelector(
  selectItemSchema,
  (state: ApplicationItemSchemaState) => {
    return {
      itemSchemaMap: state.itemSchemaMap,
      initialItemSchemaMap: state.initialItemSchemaMap,
      itemSchemaArr: state.itemSchemaArr,
      search: state.search,
      filterType: state.filterType,
      editingItem: state.editingItem,
    };
  }
);

export const {
  setEditingItem,
  setEditingItemByName,
  setItemSchema,
  setSearch,
  setFilterType,
  updateItemSchemaMapItem,
  addItemSchemaItem,
  deleteItemSchemaItem,
  alterItemSchemaItemType,
  arrayConvertItem,
  insertDraggingAsDummy,
  removeDummy,
  finalizeGridArr,
} = itemSchemaSlice.actions;

export default itemSchemaSlice.reducer;
