import type { PayloadAction } from "@reduxjs/toolkit";
import { createSlice, createSelector } from "@reduxjs/toolkit";
import {
  type GridItem,
  type GridItemMap,
  type GridArrItem,
  type SchemaComponentType,
  getCategoryFromType,
  getDefaultSchemaFromType,
  DisplaySchemaProp,
  SchemaItem,
  PortalDisplaySchema,
} from "utils/application-schema-state";
import { loadState, testDecimal, testInteger } from "utils";
import { SupportedLocale } from "components/supported-language";
import type { StandardObject } from "utils";
import type { ApplicationFormSchema } from "services/application-types";

type EditFormState = "properties" | "item" | "container";
interface ApplicationSchemaState {
  maxCol: number;
  displayLocale: SupportedLocale;
  editingSchemaKey: string | null;
  gridItems: GridItemMap;
  gridArr: GridArrItem[];
  itemSchemas: ApplicationFormSchema[];
  detailId: number | null;
  lastSavedAt: number;
  exampleData: StandardObject;
  exampleId: number | null;
  queryParams: StandardObject;
  editFormState: EditFormState;
}

export const initialState: ApplicationSchemaState = {
  maxCol: 1,
  displayLocale: "en-US",
  editingSchemaKey: null,
  gridItems: {},
  gridArr: [],
  itemSchemas: [],
  detailId: null,
  lastSavedAt: 0,
  exampleData: {},
  exampleId: null,
  queryParams: {},
  editFormState: "container",
};

export interface AddGridItemPayload {
  componentType: SchemaComponentType;
  componentProps?: StandardObject;
  parentKey?: string | string[];
}

interface UpdateGridItemSpanPayload {
  key: string;
  act: "expand" | "collapse";
  pMaxCol: number;
}

interface UpdatePropsPayload {
  componentProps: StandardObject;
  schemaProps: DisplaySchemaProp;
  locales: SchemaItem["locales"];
  maxCol?: number;
  key: string;
}

export interface InsertDraggingAsDummyPayload {
  dragKey: string;
  gridKey: string;
  parentKey?: string | string[];
}

export interface FinalizeGridArrPayload {
  dragKey: string;
}

interface SetExamplesPayload {
  exampleId: number | null;
  exampleData: StandardObject;
}

export const schemaSlice = createSlice({
  name: "schema",
  initialState,
  reducers: {
    setItemSchemas: (state, action: PayloadAction<ApplicationFormSchema[]>) => {
      state.itemSchemas = action.payload;
    },
    setExamples: (state, action: PayloadAction<SetExamplesPayload>) => {
      state.exampleData = action.payload.exampleData;
      state.exampleId = action.payload.exampleId;
    },
    setMaxCol: (state, action: PayloadAction<number>) => {
      state.maxCol = action.payload;
    },
    setDisplayLocale: (state, action: PayloadAction<SupportedLocale>) => {
      state.displayLocale = action.payload;
    },
    resetPortalSchema: (state) => {
      state.lastSavedAt = initialState.lastSavedAt;
      state.maxCol = initialState.maxCol;
      state.gridItems = initialState.gridItems;
      state.gridArr = initialState.gridArr;
      state.editingSchemaKey = initialState.editingSchemaKey;
      state.editFormState = initialState.editFormState;
    },
    loadSchema: (state, action: PayloadAction<PortalDisplaySchema>) => {
      const { maxCol, gridItems, gridArr, lastSavedAt } = action.payload;
      if (!!lastSavedAt) {
        state.lastSavedAt = lastSavedAt;
      }
      state.maxCol = maxCol;
      state.gridItems = gridItems;
      state.gridArr = gridArr;
      state.editingSchemaKey = null;
      state.editFormState = "container";
    },
    loadSchemaFromLocalStorage: (state) => {
      const { maxCol, gridItems, gridArr } = loadState("applicationSchema", [
        "maxCol",
        "gridItems",
        "gridArr",
      ]);
      state.maxCol = maxCol;
      state.gridItems = JSON.parse(gridItems);
      state.gridArr = JSON.parse(gridArr);
      state.lastSavedAt = 0;
      state.editingSchemaKey = null;
      state.editFormState = "container";
    },
    setDetailId: (state, action: PayloadAction<number | null>) => {
      state.detailId = action.payload;
    },
    cancelEditing: (state) => {
      // Check if there is parent for editing schema key
      const editingSchemaKey = state.editingSchemaKey;
      if (editingSchemaKey === null) {
        // Already not editing
        return;
      }
      const parentKey = state.gridItems[editingSchemaKey]?.parentKey;
      if (parentKey === undefined) {
        state.editingSchemaKey = null;
        state.editFormState = "container";
      } else {
        state.editingSchemaKey = parentKey;
      }
    },
    setEditingSchemaKey: (state, action: PayloadAction<string | null>) => {
      state.editingSchemaKey = action.payload;
      if (action.payload === null) {
        state.editFormState = "container";
      }
    },
    addGridItem: (state, action: PayloadAction<AddGridItemPayload>) => {
      const { componentType, componentProps, parentKey } = action.payload;
      // Generate key
      // const idx = Math.max(Object.values(state.gridItems).filter(
      //   (itm) => itm.componentType === componentType
      // ).map(itm => parseInt(itm.key.split("-")[1]) ?? 0))+1;
      const items = Object.keys(state.gridItems)
          .filter((k) => k.split("-")[0] === componentType);
      const numericItems = items.filter((k) => {
          const num = k.split("-")[1];
          return testInteger(num);
        })
      const idx = Math.max(
        ...numericItems.map((k) => parseInt(k.split("-")[1])),
        items.length - 1
      );
      const key = `${componentType}-${idx + 1}`;
      // Add grid item
      const componentCategory = getCategoryFromType(componentType);
      const defaultComponentProps = getDefaultSchemaFromType(componentType);
      const newGridItem: GridItem = {
        componentType,
        componentProps: { ...componentProps, ...defaultComponentProps },
        locales: {} as GridItem["locales"],
        parentKey: Array.isArray(parentKey) ? parentKey[-1] : parentKey,
        gridSpan: 1,
      };
      const schemaProps = getDefaultSchemaFromType(componentType);
      if (componentCategory === "CONTAINER") {
        newGridItem.maxCol = 1;
        if (Object.keys(schemaProps).length > 0) {
          newGridItem.schemaProps = schemaProps;  
        }
      } else if (componentCategory === "ITEM") {
        newGridItem.schemaProps = schemaProps;
      }
      state.gridItems[key] = newGridItem;
      // Update gridArr
      if (parentKey === undefined) {
        state.gridArr.push({ key });
      } else {
        // parent keys: array or string
        // if it is an array, it contains an array that has parent key from root level downwards
        const newGridArr = [...state.gridArr];
        let parentGridArr = newGridArr;
        const parentKeys = Array.isArray(parentKey) ? parentKey : [parentKey];
        parentKeys.forEach((k, idx) => {
          const parentGridItem = parentGridArr.find((itm) => itm.key === k);
          if (!parentGridItem) {
            return;
          }
          if (idx === parentKeys.length - 1) {
            parentGridItem.items = parentGridItem.items ?? [];
            parentGridItem.items.push({ key });
          } else {
            parentGridArr = parentGridItem.items ?? [];
          }
        });
        state.gridArr = newGridArr;
      }
      state.editingSchemaKey = key;
    },
    updateGridItemSpan: (
      state,
      action: PayloadAction<UpdateGridItemSpanPayload>
    ) => {
      const { key, act, pMaxCol } = action.payload;
      const item = state.gridItems[key];
      // Assume only root level grid item can adjust span for the moment
      let newSpan;
      if (act === "expand") {
        newSpan = item.gridSpan < pMaxCol ? item.gridSpan + 1 : pMaxCol;
      } else {
        newSpan = item.gridSpan > 1 ? item.gridSpan - 1 : 1;
      }
      item.gridSpan = newSpan;
    },
    deleteGridItem: (state, action: PayloadAction<string>) => {
      const deleteKey = action.payload;
      const gridItem = state.gridItems[deleteKey];
      const deleteParentKey = gridItem.parentKey ?? null;
      // check is editing
      if (gridItem.parentKey === undefined) {
        // Delete root level item
        state.gridArr = state.gridArr.filter((itm) => itm.key !== deleteKey);
      } else {
        // TODO: handle multiple nest level
        const parentArrItem = state.gridArr.find(
          (itm) => itm.key === gridItem.parentKey
        );
        if (!!parentArrItem && !!parentArrItem.items) {
          parentArrItem.items = parentArrItem.items.filter(
            (itm) => itm.key !== deleteKey
          );
        }
      }
      delete state.gridItems[deleteKey];
      if (state.editingSchemaKey === deleteKey) {
        state.editingSchemaKey = deleteParentKey;
        if (deleteParentKey === null) {
          state.editFormState = "container";
        }
      }
    },
    updateProps: (state, action: PayloadAction<UpdatePropsPayload>) => {
      const { key, componentProps, schemaProps, maxCol, locales } =
        action.payload;
      state.gridItems[key].componentProps = componentProps;
      const componentCategory = getCategoryFromType(
        state.gridItems[key].componentType
      );
      if (["CONTAINER", "ITEM"].includes(componentCategory) && schemaProps !== undefined) {
        state.gridItems[key].schemaProps = action.payload.schemaProps;
      }
      state.gridItems[key].locales = locales;
      if (maxCol !== undefined) {
        state.gridItems[key].maxCol = maxCol;
      }
    },
    insertDraggingAsDummy: (
      state,
      action: PayloadAction<InsertDraggingAsDummyPayload>
    ) => {
      const { dragKey, gridKey } = action.payload;
      // Check if there is parent key
      // Both parent key of dragItem & gridItem should be the same
      const dragParentKey = state.gridItems[dragKey]?.parentKey;
      const gridParentKey = state.gridItems[gridKey]?.parentKey;
      if (dragParentKey !== undefined || gridParentKey !== undefined) {
        if (dragParentKey !== gridParentKey) {
          return;
        }
      }
      const pKey = dragParentKey; // undefined or string
      // create new gridArr without dummy
      let newGridArr: GridArrItem[];
      let pIdx = -1;
      if (pKey === undefined) {
        newGridArr = [...state.gridArr].filter((itm) => itm.key !== "dummy");
      } else {
        pIdx = state.gridArr.findIndex((itm) => itm.key === pKey);
        if (pIdx < 0) {
          return;
        }
        newGridArr = [...(state.gridArr[pIdx].items ?? [])].filter(
          (itm) => itm.key !== "dummy"
        );
      }
      const dragIdx = newGridArr.findIndex((itm) => itm.key === dragKey);
      const gridIdx = newGridArr.findIndex((itm) => itm.key === gridKey);
      if (dragIdx < 0 || gridIdx < 0) {
        return;
      }

      // Update dummy item
      const dragItm = state.gridItems[dragKey];
      state.gridItems["dummy"] = {
        componentType: "dummy",
        componentProps: {},
        parentKey: pKey,
        gridSpan: dragItm.gridSpan,
      };
      if (dragIdx < gridIdx) {
        // drag forward, insert dummy after gridIdx
        newGridArr.splice(gridIdx + 1, 0, { key: "dummy" });
        // newGridArr.splice(gridIdx + 1, 1);
      } else {
        // drag backward, insert dummy before gridIdx
        newGridArr.splice(gridIdx, 0, { key: "dummy" });
      }
      if (pKey === undefined) {
        state.gridArr = newGridArr;
      } else {
        state.gridArr[pIdx].items = newGridArr;
      }
    },
    removeDummy: (state) => {
      if (state.gridItems["dummy"] === undefined) {
        return;
      }
      const dummyItm = state.gridItems["dummy"] ?? null;
      const pKey = dummyItm.parentKey;
      let pIdx = -1;
      if (pKey === undefined) {
        state.gridArr = state.gridArr.filter((itm) => itm.key !== "dummy");
      } else {
        pIdx = state.gridArr.findIndex((itm) => itm.key === pKey);
        state.gridArr[pIdx].items = state.gridArr[pIdx].items?.filter(
          (itm) => itm.key !== "dummy"
        );
      }
      delete state.gridItems["dummy"];
    },
    finalizeGridArr: (state, action: PayloadAction<FinalizeGridArrPayload>) => {
      // set dummy to dragKey and remove dragKey
      const { dragKey } = action.payload;
      // Check parentKey
      const dragParentKey = state.gridItems[dragKey]?.parentKey;
      let newGridArr: GridArrItem[];
      let dragParentIdx = -1;
      if (dragParentKey === undefined) {
        newGridArr = [...state.gridArr];
      } else {
        dragParentIdx = state.gridArr.findIndex(
          (itm) => itm.key === dragParentKey
        );
        if (dragParentIdx < 0) {
          return;
        }
        newGridArr = [...(state.gridArr[dragParentIdx].items ?? [])];
      }
      const dragIdx = newGridArr.findIndex((itm) => itm.key === dragKey);
      const dummyIdx = newGridArr.findIndex((itm) => itm.key === "dummy");
      if (dragIdx < 0 || dummyIdx < 0) {
        return;
      }
      // let newGridArr = [...state.gridArr];
      newGridArr[dummyIdx].key = dragKey;
      newGridArr[dummyIdx].items = newGridArr[dragIdx].items;
      newGridArr[dragIdx].key = "dummy";
      newGridArr = newGridArr.filter((itm) => itm.key !== "dummy");
      if (dragParentKey === undefined) {
        state.gridArr = newGridArr;
      } else {
        state.gridArr[dragParentIdx].items = newGridArr;
      }
      // Remove dummy from gridItems
      delete state.gridItems["dummy"];
    },
    setQueryParams: (state, action: PayloadAction<StandardObject>) => {
      state.queryParams = action.payload;
    },
    setEditFormState: (state, action: PayloadAction<EditFormState>) => {
      state.editFormState = action.payload
    }
  },
});

const selectSchema = (state: { schema: ApplicationSchemaState }) =>
  state.schema;

export const selectSchemaState = createSelector(
  [selectSchema],
  (s: ApplicationSchemaState) => {
    return {
      maxCol: s.maxCol,
      displayLocale: s.displayLocale,
      editingSchemaKey: s.editingSchemaKey,
      gridItems: s.gridItems,
      gridArr: s.gridArr,
      itemSchemas: s.itemSchemas,
      detailId: s.detailId,
      exampleData: s.exampleData,
      exampleId: s.exampleId,
      queryParams: s.queryParams,
      editFormState: s.editFormState,
    };
  }
);

export const {
  setItemSchemas,
  setExamples,
  setMaxCol,
  setDisplayLocale,
  setEditingSchemaKey,
  cancelEditing,
  addGridItem,
  updateGridItemSpan,
  deleteGridItem,
  updateProps,
  insertDraggingAsDummy,
  removeDummy,
  finalizeGridArr,
  loadSchema,
  loadSchemaFromLocalStorage,
  resetPortalSchema,
  setDetailId,
  setQueryParams,
  setEditFormState,
} = schemaSlice.actions;

export default schemaSlice.reducer;
