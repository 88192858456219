import React from "react";
import moment from "moment";
import buildTree, { Tree, TreeNode } from "utils/tree";
import type { FileUploadData } from "components/drag-n-drop-upload";
export type StandardObject<T = any> = Record<string, T>;

interface DummyChangeEventTarget {
  files?: FileList;
  value?: any;
  type?: string;
  name?: string;
  checked?: boolean;
  fileupload?: EventTarget & FileUploadData;
  props?: StandardObject;
  label?: string;
}
interface DummyChangeEvent {
  action?: string;
  target: DummyChangeEventTarget;
}
export type CustomChangeEvent<T> =
  | (React.ChangeEvent<T> & { action?: string })
  | DummyChangeEvent;

export const parseJwt = <T = any>(token: string): T => {
  let base64Url = token.split(".")[1];
  let base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  let jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split("")
      .map(function (c) {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join("")
  );

  return JSON.parse(jsonPayload) as T;
};

export const saveState = (groupName: string, state: StandardObject): void => {
  if (typeof window === "undefined") {
    return;
  }
  Object.entries(state).forEach(([k, v]) => {
    sessionStorage.setItem(`value/${groupName}/${k}`, v);
    sessionStorage.setItem(`type/${groupName}/${k}`, typeof v);
  });
};

export const loadState = (
  groupName: string,
  keys: string[]
): StandardObject => {
  let state: StandardObject = {};
  if (typeof window === "undefined") {
    return state;
  }
  keys.forEach((k) => {
    let storedValue = sessionStorage.getItem(`value/${groupName}/${k}`);
    let storedType = sessionStorage.getItem(`type/${groupName}/${k}`);
    if (storedValue && storedType) {
      if (storedType === "boolean") {
        if (storedValue === "true") {
          state[k] = true;
        } else if (storedValue === "false") {
          state[k] = false;
        } else {
          state[k] = undefined;
        }
      } else {
        state[k] = storedValue;
      }
    }
  });
  return state;
};

export const removeState = (groupName: string, keys: string[]): void => {
  if (typeof window === "undefined") {
    return;
  }
  keys.forEach((k) => {
    try {
      sessionStorage.removeItem(`value/${groupName}/${k}`);
    } catch (e) {
      console.error(`Failed to remove state value/${groupName}/${k}: ${e}.`);
    }
    try {
      sessionStorage.removeItem(`type/${groupName}/${k}`);
    } catch (e) {
      console.error(`Failed to remove state type/${groupName}/${k}: ${e}.`);
    }
  });
};

export const assignObject = (
  oldValues: StandardObject,
  newValues: StandardObject
): StandardObject => {
  // assign new values to existing values and return new object
  // undefined value could remove existing value
  let ret = Object.entries({ ...oldValues, ...newValues }).filter(
    ([k, v]) => v !== undefined
  );
  return Object.fromEntries(ret);
};

export const isScalarArrayEqual = (array1: any[], array2: any[]): boolean => {
  // https://stackoverflow.com/questions/7837456/how-to-compare-arrays-in-javascript
  const array2Sorted = array2.slice().sort();
  return (
    array1.length === array2.length &&
    array1
      .slice()
      .sort()
      .every(function (value, index) {
        return value === array2Sorted[index];
      })
  );
};

interface AdminRoleItem {
  id: number;
  code: string;
  parentRoleId?: number;
  requiredRoleId?: number;
  order: number;
}

export const makeRoleTree = (
  d: AdminRoleItem[],
  scopes: string[] = []
): Tree => {
  const tree = buildTree();
  const rootId = -999;
  tree.setRoot(rootId, {
    code: "Admin Roles",
    isRole: false,
  });
  if (d.length > 0) {
    d.forEach((d) => {
      let parent;
      const pid = d.parentRoleId === undefined ? rootId : d.parentRoleId;
      if (tree.contains(pid)) {
        parent = tree.getNodeById(pid);
      } else {
        parent = tree.addNew(pid, {});
      }
      if (typeof parent === "undefined") {
        return;
      }
      tree.addChildTo(parent, d.id, {
        isRole: true,
        code: d.code,
        order: d.order,
        requiredId: d.requiredRoleId ? String(d.requiredRoleId) : null,
      });
    });
  }
  // Set state index
  tree.preOrder().forEach((n: TreeNode, idx: number) => {
    n.setProperty("stateIndex", idx);
  });
  // Disable node that is not in scope
  const getRequiringNodes = (n: TreeNode): TreeNode[] => {
    // Dependent nodes are nodes with requiredId = node.id
    let ret: TreeNode[] = [];
    const queryId = String(n.id);
    tree.preOrder().forEach((n: TreeNode) => {
      const parentId = !!n.getParent() ? String(n.getParent()?.id) : null;
      if (n.getProperty("requiredId") === queryId || parentId === queryId) {
        ret.push(n);
      }
    });
    return ret;
  };
  const getAllRequiringNodes = (n: TreeNode): TreeNode[] => {
    const nodes: TreeNode[] = n.getProperty("requiringNodes");
    let ret: TreeNode[] = [...nodes];
    nodes.forEach((n) => {
      ret.push(...getAllRequiringNodes(n));
    });
    return ret;
  };
  const getChildNodes = (n: TreeNode): TreeNode[] => {
    const queryId = String(n.id);
    const queryNode = tree.getNodeById(queryId);
    return !queryNode ? [] : queryNode?.getChildren();
  };
  // Check tree checkbox checkable and disabled status
  // Non-leaf node for grouping only;
  // Each node need to check:
  // A. is disabled condition:
  //   1. if node is not in scopes
  //   2. children nodes are all disabled
  //   3. required node is disabled  (Check in second loop)
  //
  // is Not checkable condition:
  //   1. if node is disabled
  //
  // B. is conditionally checkable condition:
  //   1. if any requiring node (nodes that require this node) is disabled (is leaf)
  //      -- give visual effect & not update state
  //   2. if any offspring node is disabled (not is leaf)
  //      -- Update nodes that are not disabled only

  // First loop
  tree.postOrder().forEach((n: TreeNode) => {
    if (!n.getProperty("isRole")) {
      return;
    }
    let disabled = false;
    // Check A1
    if (!scopes.includes(n.getProperty("code"))) {
      disabled = true;
    }
    const childNodes = getChildNodes(n);
    let allChildNodesDisabled: boolean;
    if (childNodes.length === 0) {
      allChildNodesDisabled = false;
    } else {
      allChildNodesDisabled = childNodes.reduce((ret, n) => {
        return ret && n.getProperty("disabled");
      }, true);
    }
    disabled = disabled || allChildNodesDisabled;
    n.setProperty("disabled", disabled);
  });
  tree.postOrder().forEach((n: TreeNode) => {
    if (!n.getProperty("isRole")) {
      return;
    }
    const requiredId = n.getProperty("requiredId");
    // Check required node is disabled
    if (!!requiredId) {
      const requiredNode = tree.getNodeById(String(requiredId));
      if (requiredNode && requiredNode.getProperty("disabled")) {
        n.setProperty("disabled", true);
      }
    }
    let checkableNodes: string[] = [];
    const requiringNodes = getRequiringNodes(n);
    // const childNodes = getChildNodes(n);
    // const offspringNodes = tree.preOrder(n)
    // offspringNodes.forEach((cn) => {
    //    checkableNodes.push(String(cn.id));
    // });
    requiringNodes.forEach((dn) => {
      checkableNodes.push(String(dn.id));
    });
    n.setProperty("checkableNodes", [...new Set(checkableNodes)]);
    n.setProperty(
      "requiringNodes",
      requiringNodes.map((n) => n.id)
    );
  });
  return tree;
};

export const isEncryptedContent = (content: string): boolean => {
  if (!content) {
    return false;
  }
  return !!content.match(/<encrypted>(.*?)<\/encrypted>/g);
};

export const arrayIsEqual = (arr1: any[], arr2: any[]): boolean => {
  if (!(arr1 instanceof Array && arr2 instanceof Array)) {
    return false;
  }
  if (arr1.length !== arr2.length) {
    return false;
  }
  for (let i = 0; i < arr1.length; i++) {
    if (arr1[i] instanceof Array && arr2[i] instanceof Array) {
      if (!arrayIsEqual(arr1[i], arr2[i])) {
        return false;
      }
    } else if (arr1[i] instanceof Object && arr2[i] instanceof Object) {
      if (!jsonObjectIsEqual(arr1[i], arr2[i])) {
        return false;
      }
    } else if (arr1[i] !== arr2[i]) {
      return false;
    }
  }
  return true;
};

export const jsonObjectIsEqual = (
  obj1: unknown,
  obj2: unknown,
  undefinedEqualNull: boolean = false
): boolean => {
  if (obj1 instanceof Array && obj2 instanceof Array) {
    return arrayIsEqual(obj1, obj2);
  } else if (!(obj1 instanceof Object && obj2 instanceof Object)) {
    if (undefinedEqualNull) {
      // Treat undefined & null as equal
      if (
        (obj1 === null || obj1 === undefined) &&
        (obj2 === null || obj2 === undefined)
      ) {
        return true;
      }
    }
    return obj1 === obj2;
  }

  // Compare Objects
  const standObj1 = obj1 as StandardObject;
  const standObj2 = obj2 as StandardObject;
  // Sort key since Object key order is not guaranteed
  const keys1 = Object.keys(standObj1).sort();
  const keys2 = Object.keys(standObj2).sort();

  if (!undefinedEqualNull) {
    // if consider undefined and null is the same, then cannot simply check object key
    if (!jsonObjectIsEqual(keys1, keys2)) {
      return false;
    }
  }
  const checkKeys = new Set([...keys1, ...keys2]);
  for (const propName of checkKeys) {
    if (
      !jsonObjectIsEqual(
        standObj1[propName],
        standObj2[propName],
        undefinedEqualNull
      )
    ) {
      return false;
    }
  }
  return true;
};

export const getObjectUpdates = (
  origConf: StandardObject,
  newConf: StandardObject
): StandardObject => {
  // Only works for non-nested object comparsion
  let updates = Object.entries(newConf).reduce<StandardObject>((sm, [k, v]) => {
    let oldValue = origConf[k];
    // const isModified = !jsonObjectIsEqual(oldValue, v, true);
    let isModified;
    if (
      typeof v === "object" &&
      typeof oldValue === "object" &&
      oldValue !== null &&
      oldValue !== undefined
    ) {
      // If new value is object and old value exist
      isModified = !jsonObjectIsEqual(oldValue, v, true);
    } else {
      isModified = oldValue !== v;
    }
    if (isModified) {
      if (v === "" || v === null) {
        sm[k] = null;
      } else if (v instanceof Array && !v.length) {
        sm[k] = null;
      } else {
        sm[k] = v;
      }
    }
    return sm;
  }, {});
  return updates;
};

export const checkIsUndefined = (val: unknown): boolean =>
  typeof val === "undefined";

export const updateNestedObjByPath = (
  nestedObj: StandardObject,
  fieldpath: string,
  newValue: any
): void => {
  // Update nestedObject in place, with path syntax a.b.c.d
  const paths = fieldpath.split(".");
  let iobj = newValue;
  if (paths.length > 1) {
    paths
      .slice()
      .reverse()
      .forEach((ipath, i) => {
        let jobj = nestedObj;
        if (i < paths.length - 1) {
          paths.forEach((jpath, j) => {
            if (j < paths.length - 1 - i) {
              jobj = jobj[jpath];
              if (typeof jobj === "undefined") {
                console.error(
                  `${jpath} not found when updating ${fieldpath} on:`,
                  jobj
                );
              }
            }
          });
          jobj[ipath] = iobj;
          iobj = jobj;
        }
      });
  } else {
    nestedObj[paths[0]] = newValue;
  }
};

interface WindowDimensions {
  width: number;
  height: number;
}

export const getWindowDimensions = (): WindowDimensions => {
  if (typeof window === "undefined") {
    return {
      width: 0,
      height: 0,
    };
  }
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
};

export const getMimeTypeFromFileExtension = (fext: string): string => {
  const types: StandardObject<string> = {
    html: "text/html",
    css: "text/css",
    xml: "text/xml",
    gif: "image/gif",
    jpg: "image/jpeg",
    jpeg: "image/jpeg",
    js: "application/x-javascript",
    atom: "application/atom+xml",
    rss: "application/rss+xml",
    mml: "text/mathml",
    txt: "text/plain",
    jad: "text/vnd.sun.j2me.app-descriptor",
    wml: "text/vnd.wap.wml",
    htc: "text/x-component",
    png: "image/png",
    tiff: "image/tiff",
    wbmp: "image/vnd.wap.wbmp",
    ico: "image/x-icon",
    jng: "image/x-jng",
    bmp: "image/x-ms-bmp",
    svg: "image/svg+xml",
    webp: "image/webp",
    jar: "application/java-archive",
    hqx: "application/mac-binhex40",
    doc: "application/msword",
    pdf: "application/pdf",
    ps: "application/postscript",
    rtf: "application/rtf",
    xls: "application/vnd.ms-excel",
    ppt: "application/vnd.ms-powerpoint",
    wmlc: "application/vnd.wap.wmlc",
    kml: "application/vnd.google-earth.kml+xml",
    kmz: "application/vnd.google-earth.kmz",
    "7z": "application/x-7z-compressed",
    cco: "application/x-cocoa",
    jardiff: "application/x-java-archive-diff",
    jnlp: "application/x-java-jnlp-file",
    run: "application/x-makeself",
    pl: "application/x-perl",
    pdb: "application/x-pilot",
    rar: "application/x-rar-compressed",
    rpm: "application/x-redhat-package-manager",
    sea: "application/x-sea",
    swf: "application/x-shockwave-flash",
    sit: "application/x-stuffit",
    tcl: "application/x-tcl",
    pem: "application/x-x509-ca-cert",
    xpi: "application/x-xpinstall",
    xhtml: "application/xhtml+xml",
    zip: "application/zip",
    mid: "audio/midi",
    mp3: "audio/mpeg",
    ogg: "audio/ogg",
    ra: "audio/x-realaudio",
    "3gp": "video/3gpp",
    mpg: "video/mpeg",
    mov: "video/quicktime",
    flv: "video/x-flv",
    mng: "video/x-mng",
    asf: "video/x-ms-asf",
    wmv: "video/x-ms-wmv",
    avi: "video/x-msvideo",
    mp4: "video/mp4",
  };
  const fileExtension = fext.toLowerCase();
  return types[fileExtension] ? types[fileExtension] : "";
};

export function downloadBlob(blob: Blob, filename: string): void {
  const link = document.createElement("a");
  link.href = URL.createObjectURL(blob);
  link.download = filename;
  link.addEventListener("click", () => {
    setTimeout(() => {
      URL.revokeObjectURL(link.href);
    }, 30000);
  });
  link.click();
}

export function downloadURI(uri: string, name: string): void {
  fetch(uri)
    .then((response) => response.blob())
    .then((blob) => {
      downloadBlob(blob, name);
    })
    .catch(console.error);
}

export const handleUpload = (
  fileOnChange: (files: FileList | null) => void
) => {
  const input = document.createElement("input");
  input.type = "file";
  input.style.display = "none";
  input.onchange = (event: Event) => {
    const target = event.target as HTMLInputElement;
    if (target && target.files) {
      fileOnChange(target.files);
    }
  };
  document.body.appendChild(input);
  input.click();
  document.body.removeChild(input);
};

export const getHandleOnKeyUp =
  (ref: React.RefObject<HTMLDivElement>) =>
  (evt: KeyboardEvent): void => {
    const { keyCode } = evt;
    if (keyCode === 13) {
      if (ref && ref.current) {
        let inputs = Array.from(ref.current.querySelectorAll("input")).filter(
          (elem) => !elem.disabled
        );
        let currentInputIdx = inputs.findIndex((elem) => elem === evt.target);
        if (inputs.length && currentInputIdx < inputs.length) {
          if (currentInputIdx > -1) {
            if (!inputs[currentInputIdx].value) {
              return;
            }
          }
          if (currentInputIdx < inputs.length - 1) {
            inputs[currentInputIdx + 1].focus();
          }
        }
      }
    }
  };

export const hashCode = (s: string): number => {
  return s.split("").reduce((a, b) => {
    a = (a << 5) - a + b.charCodeAt(0);
    return a & a;
  }, 0);
};

export const checkBase64ContentIsPng = (encodedContent: string): boolean => {
  if (encodedContent.slice(0, 12) === "iVBORw0KGgoA") {
    return true;
  } else {
    return false;
  }
};

export const checkBase64ContentIsJpg = (encodedContent: string): boolean => {
  if (encodedContent.slice(0, 4) === "/9j/") {
    return true;
  } else {
    return false;
  }
};

export const camelToFlat = (c: string): string => (
  (c = c.replace(/[A-Z]/g, " $&")), c[0].toUpperCase() + c.slice(1)
);

export const testInteger = (v: string | number): boolean => {
  const regex = /^-?[0-9]+$/;
  return regex.test(String(v));
};

export const testDecimal = (v: string | number): boolean => {
  const regex = /^-?[0-9]+(\.[0-9]*)?$/;
  return regex.test(String(v));
};

export const hexToRgb = (hex: string): { r: number; g: number; b: number } => {
  let r = 0,
    g = 0,
    b = 0;
  // 3 digits
  if (hex.length === 4) {
    r = parseInt(hex[1] + hex[1], 16);
    g = parseInt(hex[2] + hex[2], 16);
    b = parseInt(hex[3] + hex[3], 16);
  }
  // 6 digits
  if (hex.length === 7) {
    r = parseInt(hex[1] + hex[2], 16);
    g = parseInt(hex[3] + hex[4], 16);
    b = parseInt(hex[5] + hex[6], 16);
  }
  return { r, g, b };
};

export const getLuminance = (hex: string): number => {
  const rgb = hexToRgb(hex);
  return 0.299 * rgb.r + 0.587 * rgb.g + 0.114 * rgb.b;
};

export const getContrastTextHex = (background: string): string => {
  const bgLuminance = getLuminance(background);
  return bgLuminance > 125 ? "#000000" : "#ffffff";
};

export const formatPdfTimestamp = (pdfTimestamp: string) => {
  // Remove the "D:" prefix
  let cleanedTimestamp = pdfTimestamp.replace("D:", "");

  // Replace the offset format from +08'00' to +08:00 for Moment.js compatibility
  cleanedTimestamp = cleanedTimestamp.replace(
    /([+-])(\d{2})'(\d{2})'/,
    "$1$2:$3"
  );

  // Parse the timestamp with Moment.js
  const date = moment(cleanedTimestamp, "YYYYMMDDHHmmssZ");

  // Format to YYYY-MM-DD HH:mm:ss ZZ
  return date.format("YYYY-MM-DD HH:mm:ss ZZ");
};

export const formatStringToFileName = (
  str: string,
  maxChar?: number
): string => {
  // Replace spaces with underscores
  let fileName = str.replace(/\s+/g, "_");

  // Remove or replace invalid characters
  fileName = fileName.replace(/[\/\\:*?"<>|]/g, "_");

  // Optionally, remove or replace other special characters
  fileName = fileName.replace(/[^a-zA-Z0-9._-]/g, "");

  // Convert to lowercase (optional)
  fileName = fileName.toLowerCase();

  // Truncate to 255 characters (optional)
  if (fileName.length > (maxChar ?? 20)) {
    fileName = fileName.substring(0, maxChar ?? 20);
  }

  // Ensure it’s not empty; provide a default if it is
  return fileName || "default_filename";
};

export const getTimezoneName = (guessedTz: string, language: string) => {
  console.log(language);
  const formatter = new Intl.DateTimeFormat(language, {
    timeZone: guessedTz,
    timeZoneName: 'long', // 'long' gives full name (e.g., "Eastern Daylight Time")
  });
  const parts = formatter.formatToParts(new Date());
  return parts.find((part) => part.type === 'timeZoneName')?.value || guessedTz;
}
