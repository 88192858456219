import React, { createContext, useContext, useState, useCallback } from "react";
import SnackbarMessage from "components/snackbar-message";
import type { AlertColor } from "@mui/material/Alert";

export interface NotificationContextType {
  showNotification: (msg: string, type: AlertColor) => void;
  hideNotification: () => void;
};
const NotificationContext = createContext<NotificationContextType>(
  {} as NotificationContextType
);

export const useNotification = (): NotificationContextType => {
  return useContext(NotificationContext);
};

export const NotificationProvider: React.FC<React.PropsWithChildren<{}>> = ({
  children }) => {
  const [open, setOpen] = useState<boolean>(false);
  const [message, setMessage] = useState<string>("");
  const [severity, setSeverity] = useState<AlertColor>("success");
  const showNotification = useCallback((msg: string, type: AlertColor): void => {
    setMessage(msg);
    setSeverity(type);
    setOpen(true);
  }, []);

  const hideNotification = useCallback((): void => {
    setOpen(false);
  }, []);

  return (
    <NotificationContext.Provider
      value={{ showNotification, hideNotification }}
    >
      {children}
      <SnackbarMessage
        open={open}
        message={message}
        severity={severity}
        onClose={hideNotification}
      />
    </NotificationContext.Provider>
  );
};
