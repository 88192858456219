import type { PayloadAction } from "@reduxjs/toolkit";
import { createSlice, createSelector } from "@reduxjs/toolkit";
import { createSetQueryParamsReducer } from "./common";
import { DocumentType, DocumentStatus } from "services/fubon-credit-application-types";
import { ApplicationFormSchema } from "services/application-types";
import { GridArrItem, GridItemMap, PortalDisplaySchema } from "utils/application-schema-state";
import { Portal } from "@mui/material";

// type ApplicationDocumentStatus = "UPLOADED" | "SUBMITTED" | "DELETED";

interface ApplicationState {
  queryParams: Record<string, any>;
  formId: string | null;
  itemSchemas: ApplicationFormSchema[];
  portalDisplaySchema: PortalDisplaySchema | null;
}

const initialState: ApplicationState = {
  queryParams: {},
  formId: null,
  itemSchemas: [],
  portalDisplaySchema: null,
};

export interface SetFormPayload {
  formId: string | null;
  itemSchemas: ApplicationFormSchema[];
  portalDisplaySchema: PortalDisplaySchema | null;
}

export const applicationSlice = createSlice({
  name: "application",
  initialState,
  reducers: {
    setQueryParams: createSetQueryParamsReducer("queryParams"),
    setForm: (state, action: PayloadAction<SetFormPayload>) => {
      state.formId = action.payload.formId;
      state.itemSchemas = action.payload.itemSchemas;
      state.portalDisplaySchema = action.payload.portalDisplaySchema;
    },
  },
});

const selectApplication = (state: { application: ApplicationState }) => state.application;

export const selectApplicationState = createSelector(
  [selectApplication],
  (s: ApplicationState) => {
    return {
      queryParams: s.queryParams,
      formId: s.formId,
      itemSchemas: s.itemSchemas,
      portalDisplaySchema: s.portalDisplaySchema,
    };
  }
);

export const {
  setQueryParams,
  setForm,
} = applicationSlice.actions;

export default applicationSlice.reducer;
