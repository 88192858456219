import React from "react";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import type { AlertColor } from "@mui/material/Alert";
import type { SnackbarCloseReason } from "@mui/material/Snackbar";

interface SnackbarMessageProps {
  open: boolean;
  message: string;
  severity: AlertColor;
  onClose: () => void;
}

const SnackbarMessage: React.FC<SnackbarMessageProps> = ({
  open,
  message,
  severity,
  onClose,
}) => {
  const handleClose = (event: Event | React.SyntheticEvent, reason: SnackbarCloseReason) => {
    if (reason === "clickaway") {
      return;
    }
    onClose();
  }

  return (
    <Snackbar
      open={open}
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
      autoHideDuration={6000}
      onClose={handleClose}
    >
      <Alert onClose={onClose} severity={severity} sx={{ width: "100%" }}>
        {message}
      </Alert>
    </Snackbar>
  );
};

export default SnackbarMessage;
