import React from "react";

import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";

export type SupportedLocale =
  | "en-US"
  | "en-HK"
  | "zh-HK"
  | "zh-CN"
  | "ja-JP"
  | "sv-SE";
export interface SupportedLanguageOption {
  code: SupportedLocale;
  name: string;
  displayName: string;
}

export const supportedLanguages: SupportedLanguageOption[] = [
  {
    code: "en-US",
    name: "ENGLISH",
    displayName: "English",
  },
  {
    code: "zh-HK",
    name: "TRADITIONAL_CHINESE",
    displayName: "​繁體中文",
  },
];

export const defaultLanguage = "en-US";

export const LanguageSelect: React.FC<React.ComponentProps<typeof Select>> = ({
  ...props
}) => (
  <Select {...props}>
    {supportedLanguages.map((lang, idx) => (
      <MenuItem key={`langselect-${lang.code}`} value={lang.code}>
        {lang.displayName}
      </MenuItem>
    ))}
  </Select>
);

export const getLocaleFromI18nLang = (lang: string): SupportedLocale => {
  switch (lang) {
    case "en":
      return "en-US";
    case "zh":
      return "zh-HK";
    default:
      return "en-US";
  }
}
